import React from "react"
import * as components from '../components';
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = (props) => {
  const { location } = props;
  const url = location.href ? location.href : '';

  const data = useStaticQuery(
    graphql`
        query routePageQuery {
          allMarkdownRemark(sort: { order: ASC, fields: [fields___slug] }) {
            nodes {
              fields {
                  slug
              }
              id
              frontmatter {
                  title
              }
            }
          }
      }
    `
  );
  return (
    <components.Layout>
      <components.Home url={url}/>
      {/* <components.Seo title="Home" />
      <components.SideBarContents />
      <components.IntroductionCarousel />
      <components.ExpertiseInfo />
      <components.Section></components.Section> 
      <components.Section></components.Section> 
      <components.Section></components.Section> 
      <components.TechnologyPartner />
      <components.CaseStudy />
      <components.BlogSection /> */}
    </components.Layout>
  )
}

export default IndexPage
